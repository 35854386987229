exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-covid-tsx": () => import("./../../../src/pages/covid.tsx" /* webpackChunkName: "component---src-pages-covid-tsx" */),
  "component---src-pages-experience-feedback-tsx": () => import("./../../../src/pages/experience-feedback.tsx" /* webpackChunkName: "component---src-pages-experience-feedback-tsx" */),
  "component---src-pages-experience-tsx": () => import("./../../../src/pages/experience.tsx" /* webpackChunkName: "component---src-pages-experience-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-waitlist-tsx": () => import("./../../../src/pages/waitlist.tsx" /* webpackChunkName: "component---src-pages-waitlist-tsx" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-service-details-js": () => import("./../../../src/templates/service-details.js" /* webpackChunkName: "component---src-templates-service-details-js" */)
}

